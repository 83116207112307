.headerPlan .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  display: none; }

.headerPlan .ant-modal-content {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .headerPlan .ant-modal-content .ant-modal-header {
    border-radius: 10px; }
  .headerPlan .ant-modal-content .calenderItem {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3B3B3B;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    padding-bottom: 2px;
    margin-bottom: 5px; }
  .headerPlan .ant-modal-content .calenderItem:last-child {
    border-bottom: none; }
  .headerPlan .ant-modal-content .calenderItem:hover {
    background: #E3E3E5;
    border-radius: 4px; }
  .headerPlan .ant-modal-content .ant-fullcalendar-content::-webkit-scrollbar {
    width: 9px; }
  .headerPlan .ant-modal-content .ant-fullcalendar-content::-webkit-scrollbar-track {
    width: 7px;
    background: #F9F9F9;
    border: 1px solid #EDEDED;
    border-radius: 3px; }
  .headerPlan .ant-modal-content .ant-fullcalendar-content::-webkit-scrollbar-thumb {
    width: 7px;
    background: #C7C7C7;
    border-radius: 3px; }

.ant-form-item {
  margin-bottom: 10px;
  font-size: 13px; }
  .ant-form-item .has-error {
    position: relative; }
    .ant-form-item .has-error .ant-form-explain, .ant-form-item .has-error .ant-form-split {
      position: absolute;
      bottom: -8px;
      min-height: 10px;
      line-height: 10px;
      font-size: 10px; }
  .ant-form-item .ant-form-item-label label {
    font-size: 13px; }
  .ant-form-item .ant-form-item-control-wrapper input {
    font-size: 13px; }
  .ant-form-item .ant-form-item-control-wrapper .ant-checkbox + span {
    padding: 0;
    padding-left: 4px; }
  .ant-form-item .ant-select-selection__placeholder {
    font-size: 13px; }
  .ant-form-item .ant-input {
    font-size: 13px; }
  .ant-form-item .ant-checkbox-wrapper {
    font-size: 13px; }

.ant-table .ant-table-thead > tr > th, .ant-table .ant-table-tbody > tr > td {
  font-size: 13px;
  padding: 10px; }

.ant-table .ant-table-thead > tr > th {
  font-weight: bold; }

.ant-table .ant-table-expanded-row {
  background-color: #ffffff; }

.ant-dropdown-menu .ant-dropdown-menu-item {
  font-size: 13px; }

.ant-btn {
  font-size: 13px; }

.ant-table-fixed-header .ant-table-scroll .ant-table-body, .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow-y: auto !important; }

.ant-modal-content .ant-modal-header {
  padding: 10px 24px; }

.ant-modal-content .ant-modal-close-x {
  height: 42px;
  line-height: 42px; }

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  font-size: 14px; }

.ant-drawer .ant-drawer-content-wrapper .ant-drawer-body {
  padding: 0; }

.ant-collapse .ant-collapse-content {
  overflow: inherit; }

.ant-back-top .ant-back-top-content {
  background-color: #1088e9; }
  .ant-back-top .ant-back-top-content:hover {
    background-color: #40a9ff; }
